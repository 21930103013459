footer.content-info {
	background-color: $black-font;
	position: relative;
	padding: 80px 0;
	p {
		color: white;
		/*font-size: 18px;*/
		@include rfs(1.8rem);
		line-height: 36px;
		font-weight: 600;
		margin-bottom: 40px;
	}
	section {
		.title {
			/*font-size: 15px;*/
			@include rfs(1.5rem);
			font-weight: 700;
		}
	}

	.custom-html-widget {
		> img.logo {
			margin-bottom: 30px;
			max-width: 200px;
		}

		> a {
			> i {
				/*font-size: 35px;*/
				@include rfs(3.5rem);
			}
		}
	}

	.widget_nav_menu {
		ul.menu {
			list-style: none;
			padding: 0;
			line-height: 24px;
			> li {
				> a {
					/*font-size: 14px;*/
					@include rfs(1.4rem);
					line-height: 36px;
					color: $gray-font-aaa;
					text-decoration: none;
					&:hover {
						color: $white;
					}
				}
			}
		}
	}
	.mention {
		margin: auto 0;	
	}
}

.logo-social-row-footer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	margin: 30px -30px;
	&:after,
	&:before {
		display: none;
	}
	.logo-social-footer {
		img {
			max-width: 100%;
			width: 250px;
		}
	}
	.logo-rhexpert {
		margin: 0 0 30px 0;
	}
}

.social {
	flex-direction: row;
	display: flex;
	img {
		width: 100%;
		height: 100%;
	}
	a {
		width: 30px;
		height: 30px;
		margin-right: 20px;
	}
}

.logo-social-row-footer {
	img {
		width: 100%;
	}
	.widget {
		.title {
			margin-top: 0;
		}
	}
	.widget-1 {
		p {
			a {
				font-weight: bold;
			}
		}
	}
	.widget {
		p {
		}
		a {
		}
	}
}
.widget-last {
	text-align: right;
	h3 {
		margin-bottom: 25px !important;
	}
	.textwidget {
		.link-tel {
			color: white;
			/*font-size: 32px;*/
			@include rfs(3.2rem);
			line-height: 36px;
			text-decoration: none;
			&:hover {
				color: $white;
			}
		}
		p {
			/*font-size: 14px;*/
			@include rfs(1.4rem);
			line-height: 36px;
			color: $gray-font-aaa;
			margin-bottom: 40px;
		}
		.bloc-svg {
			margin-top: 20px;
			svg {
				margin-left: 20px;
			}
		}
	}
}

.container.credit-signature {
	margin-top: 60px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	/*font-size: 14px;*/
	@include rfs(1.4rem);
	line-height: 36px;
	color: $gray-font-aaa;
	a {
		color: $gray-font-aaa;
		margin-left: 30px;
		text-decoration: none;
		&:hover {
			color: $white;
		}
	}
	&:before,
	&:after {
		display: none;
	}
}

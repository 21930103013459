#partenaires {
  padding: 120px 0;
  position: relative;
  overflow: hidden;
  h2 {
    /*font-size: 48px;*/
    @include rfs(4.8rem);
    line-height: 58px;
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: $screen-md) {
      display: initial;
    }
  }
}

.slick {
  img {
    margin: 0 auto;
    max-width: 170px;
    height: auto;
  }
}
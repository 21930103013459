#section-blog, #blog {
  padding-top: 120px;
  .bloc-post {
    position: relative;
    &:hover {
      .lire-plus a::after {
        transform: translate(10px);
        transition: all 0.5s;
      }
    }
    @media (max-width: $screen-lg) {
      &:not(:last-child) {
        margin-bottom: 50px;
      }
    }
    .cover-post {
      width: 100%;
      height: 208px;
      border-radius: 30px 30px 0 0;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  h2 {
    margin-bottom: 60px;
  }
  h2,
  p {
    text-align: center;
  }
  .info-post {
    padding: 32px 40px 40px 40px;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 30px 30px;
    background-color: $gris-eee;
    .lire-plus {
      margin-top: 10px;
      display: flex;
      font-weight: bold;
      a {
        display: flex;
        transition: all 0.5s;
        &:hover {
          text-decoration: none;
          &::after {
            transform: translate(10px);
            transition: all 0.5s;
          }
        }
        &::after {
          content: "";
          margin-left: 10px;
          width: 50px;
          background-image: url(../images/arrow-gray.svg);
          height: auto;
          display: block;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: 50%;
          transform: translate(0);
          transition: all .5s;
        }
      }
      &:hover {
        text-decoration: none;
      }
    }
    .link-title {
      color: $bleu-handi;
      /*font-size: 24px;*/
      @include rfs(2.4rem);
      line-height: 33px;
      font-weight: bold;
      margin-bottom: 20px;
      text-decoration: none;
      &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }
    }
    p {
      /*font-size: 16px;*/
      @include rfs(1.6rem);
      line-height: 26px;
      font-weight: lighter;
    }
    a {
      color: #333;
      font-weight: bold;
    }
    a,
    link-title,
    p {
      text-align: left;
    }
  }
  .link-blog {
    .lire-plus {
      margin-top: 60px;
      float: right;
    }
    a {
      color: $bleu-handi;
      font-weight: bold;
      display: flex;
      justify-content: center;
      &:hover {
        text-decoration: none;
        svg {
          transform: translateX(10px);
          transition: transform .5s;
        }
      }
      svg {
        position: relative;
        transform: translateX(0);
        transition: transform .5s;
      }
    }
  }
  .link-arrow {
    position: relative;
    flex-direction: row;
    svg {
      margin: auto 0 auto 10px;
    }
  }
}

#intro-blog {
  margin: 50px 0 25px;
}

#section-blog-actu {
  margin-bottom: 50px;
  .vc_grid-filter {
    margin-bottom: 30px;
    padding-top: 30px;
    border-top: solid 1px $rose-handi;
  }
  .vc_btn3-container {
    display: none;
  }
  .vc_gitem-animated-block  {
    .vc_gitem-zone {
      border-radius: 30px 30px 0 0;
      max-height: 175px;
    }
  }
  .vc_gitem_row .vc_gitem-col {
    padding: 0;
  }
  .vc_gitem-zone {
    padding: 32px 40px 40px;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 30px 30px;
    background-color: #eee !important;
    h4 {
      margin-top: 0;
      color: $bleu-handi;
      /*font-size: 24px;*/
      @include rfs(2.4rem);
      line-height: 33px;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
  .lire-plus {
    margin-top: 10px;
    display: flex;
    font-weight: bold;
    a {
      display: flex;
      transition: all 0.5s;
      &:hover {
        text-decoration: none;
        &::after {
          transform: translate(10px);
          transition: all 0.5s;
        }
      }
      &::after {
        content: "";
        margin-left: 10px;
        width: 50px;
        background-image: url(../images/arrow-gray.svg);
        height: auto;
        display: block;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50%;
        transform: translate(0);
        transition: all .5s;
      }
    }
    &:hover {
      text-decoration: none;
    }
  }
  p {
    /*font-size: 16px;*/
    @include rfs(1.6rem);
    line-height: 26px;
    font-weight: lighter;
  }
}

.single-post {
  .section-cover-picture {
    height: 400px;
  }
  .wrap.container {
    padding-bottom: 25px;
    border-bottom: solid 1px $rose-handi;
  }
  h1 {
    text-align: center;
    margin: 50px;
    color: $bleu-handi;
  }
}

#blog {
  padding-top: 60px;
}
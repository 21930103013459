header.banner {
  background-color: #fff;
  padding         : 27px 0;
  position        : relative;
  z-index         : 1000;

  // &::before {
  //   content         : '';
  //   background-color: #fff;
  //   position        : absolute;
  //   top             : 0;
  //   right           : 0;
  //   bottom          : 0;
  //   left            : 0;
  //   z-index         : 10;
  // }

  &::after {
    content   : '';
    position  : absolute;
    top       : 100%;
    display   : block;
    width     : 100%;
    height    : 6px;
    background: linear-gradient(to right, #c90062 0%, #fecb00 49.75%, #2a6ebb 100%);
  }

  ul {
    list-style   : none;
    margin-bottom: 0;
    padding-left : 0;
  }

  a {
    color: #333333 !important;
  }

  .container {

    nav {
      display        : flex;
      justify-content: flex-start;

      a.brand {
        display     : flex;
        align-items : center;
        margin-right: 63px;

        img {
          height: 64px;

          @media only screen and (max-width: 1535px) {
            height: 54px;
          }
        }
      }

      .hamburger-menu-responsive {
        display: none;
      }

      .nav-right {
        display        : flex;
        align-items    : center;
        justify-content: space-between;
        flex           : 1;

        .left {
          margin-right: 58px;

          .top {
            display       : flex;
            align-items   : center;
            padding-bottom: 16px;
          }

          .bottom {
            border-top: 1px solid #DDDDDD;
          }
        }

        .right {
          display    : flex;
          align-items: center;

          .tel,
          .devis {
            padding       : 14px 20px;
            border-radius : 100px;
            font-weight   : bold;
            font-size     : 20px;
            line-height   : 22px;
            letter-spacing: -.020rem;


          }

          .devis {
            @media only screen and (max-width: 1535px) {
              font-size: 17px;
              padding  : 9px 14px;
            }
          }

          .tel {
            margin-right    : 16px;
            background-color: #fff;
            font-size       : 20px;
            line-height     : 22px;
            width           : max-content;
            display         : flex;
            align-items     : center;

            img {
              margin-right: 8px;
              display     : block;
              flex        : 0 0 20px;
            }

            .text {
              font-size     : 20px;
              line-height   : 22px;
              letter-spacing: -.020rem;
            }

            @media only screen and (max-width: 1535px) {
              padding: 0;

              img {
                height: 40px;
              }

              .text {
                display: none;
              }
            }
          }
        }

        .devis {
          background-color: #000;
          font-weight     : bold;
          color           : #fff !important;
          width           : max-content;
          text-decoration : none;
        }
      }
    }
  }
}


#menu-primary-top {
  display    : flex;
  align-items: center;

  li {

    a {
      font-size  : 14px;
      line-height: 14.3px;
      padding    : 0 20px;
    }
  }
}

#menu-primary-bottom {
  display        : flex;
  align-items    : center;
  justify-content: flex-start;

  >li {
    cursor: pointer;

    >span {
      padding       : 20px 20px;
      display       : block;
      font-size     : 20px;
      line-height   : 22px;
      font-weight   : bold;
      width         : max-content;
      pointer-events: none;
      display       : flex;
      align-items   : center;

      &::after {
        content            : '';
        background-image   : url('../images/arrow-down-b.svg');
        background-repeat  : no-repeat;
        background-position: center center;
        background-size    : 12px 12px;
        height             : 20px;
        width              : 20px;
        display            : block;
        margin-left        : 2px;
        transform          : rotate(0deg);
        transition         : transform 0.3s ease-in-out;
      }

      @media only screen and (max-width: 1574px) {
        padding: 20px 15px;
      }
    }

    .sub-menu {
      display            : none;
      // transform       : translateY(-100%);
      position           : absolute;
      top                : 100%;
      background-color   : #fff;
      width              : 100%;
      left               : 0;
      right              : 0;
      padding            : 56px 0;
      // transition      : transform 0.3s ease-in-out;
      // z-index         : 9;

      .container {
        display              : grid;
        grid-template-columns: repeat(12, 1fr);
        gap                  : 24px;

        &.is_pro {
          .wrapper {
            grid-column    : 4 / -1;
            display        : flex;
            align-items    : flex-start;
            justify-content: space-between;

            #menu-professionnels-gamme-pro {
              a {
                font-weight: bold;
              }
            }

            .sub-menu-item:nth-child(2) {
              position: relative;

              &::before {
                content         : '';
                position        : absolute;
                height          : 100%;
                width           : 1px;
                background-color: #DDDDDD;
                top             : 0;
                bottom          : 0;
                left            : -40px;
              }
            }
          }


        }

        .menu-back {
          display: none;
        }

        .sub-menu-header {
          grid-column: 1 / span 3;

          .title {
            font-size    : 40px;
            line-height  : 44px;
            display      : block;
            margin-bottom: 12px;
            font-weight  : 200;
          }

          .link {
            color         : #C90062;
            text-transform: uppercase;
            font-weight   : bold;
            font-size     : 14px;
            line-height   : 22px;
            cursor        : pointer;
          }
        }

        .sub-menu-item {
          grid-column: auto / span 2;
          padding-top: 16px;

          >.title {
            font-size    : 20px;
            line-height  : 22px;
            display      : block;
            margin-bottom: 20px;
            font-weight  : bold;
          }


          >ul {
            li {
              &:not(:last-child) {
                margin-bottom: 16px;
              }

              a {
                line-height    : 22px;
                font-size      : 16px;
                text-decoration: none;
              }
            }
          }
        }
      }

    }

    &.active {
      >span {
        &::after {
          transform : rotate(180deg);
          transition: transform 0.3s ease-in-out;
        }
      }

      .sub-menu {
        display      : block;
        // transform : translateY(0);
        // transition: transform 0.3s ease-in-out;
      }
    }
  }
}
.slick-subservice {
  margin-top: 20px;
  width: 50%;

  .item {
    img {
      height: 300px;
      width: auto;
      padding: 0 10px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .slick-subservice {
    width: 100%;

    .item {
      img {
        padding: 0 5px;
      }
    }
  }
}
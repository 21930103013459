.breadcrumb-top {
    margin-bottom: 2rem;
    margin-top: 40px;
    display: flex;
    align-items: center;
    @media (max-width: $screen-sm) {
        display: none;
    }

    
    &.mr-left {
        @media (min-width: $screen-md) {
            margin-left: 18rem;
        }
    }

    & > span {
        font-weight: 700;
        font-size: 1.4rem;

        &:not(:first-child) {
            margin-left: 10px;
        }
        & > a {
            color: $black-font;
        }
    }
    .current-item {
        color: $rose-handi;
    }
}

.back-btn {
    float: right;
    cursor: pointer;
    @media (max-width: $screen-sm) {
        margin-top: 50px;
    }
    span {
        color: $rose-handi;
    }
    a {
        color: $rose-handi;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}

#contact-form {
  padding-top: 80px;

  label {
    font-size: 18px;
    font-weight: normal;
  }

  .btn {
    padding: 5px 30px;
    font-size: 18px;
  }

  .link-tel {
    color: $rose-handi;
  }

  textarea {
    resize: none;
  }
}

svg {
  &.svg-linkedin {
    .a{fill:#aaa;}.b{fill:#333;}
    &:hover {
      .a{fill:#fff;}
    }
  }
  &.svg-instagram {
    .a{fill:#aaa;}.b{fill:#333;}
    &:hover {
      .a{fill:#fff;}
    }
  }
  &.svg-facebook {
    .a{fill:#aaa;}.b{fill:#333;}
    &:hover {
      .a{fill:#fff;}
    }
  }
}

@media only screen and (max-width: 1304px) {

  header.banner {
    position: relative;


    &.menu-mobile-open {
      .nav-right {
        display: flex !important;
      }
    }

    .container {

      nav {
        display        : flex;
        justify-content: space-between;

        a.brand {
          display     : flex;
          align-items : center;
          margin-right: unset;

          img {
            height: 40px;
          }
        }

        .hamburger-menu-responsive {
          display       : flex;
          flex-direction: column;
          align-items   : center;
          cursor        : pointer;

          & * {
            pointer-events: none;
          }

          img {
            flex: 0 0 32px;
          }

          .text {
            font-size     : 11px;
            font-weight   : bold;
            letter-spacing: -.020rem;

          }
        }

        .nav-right {
          padding-top     : 20px;
          display         : none;
          flex-direction  : column;
          position        : absolute;
          top             : 100%;
          background-color: white;
          width           : 100vw;
          left            : 0;
          right           : 0;
          height          : calc(100vh - 97px);


          .left {
            margin-right  : 0;
            width         : 100%;
            display       : flex;
            flex-direction: column;

            #menu-primary-top,
            #menu-primary-bottom {
              display       : flex;
              flex-direction: column;
              align-items   : flex-start;
            }

            .top {
              margin-top: 32px;
              order     : 2;

              #menu-primary-top {
                li {
                  a {
                    padding-left : 33px;
                    padding-right: 33px;

                    &:first-child {
                      margin-bottom: 20px;
                      display      : block;
                    }
                  }
                }
              }
            }

            .bottom {
              order     : 1;
              border-top: unset;

              #menu-primary-bottom {
                order: 1;

                >li {
                  width        : 100%;
                  border-bottom: 1px solid #EEEEEE;

                  span {
                    width          : 100%;
                    display        : flex;
                    align-items    : center;
                    justify-content: space-between;
                    padding-left   : 0;
                    padding-right  : 0;
                    padding-left   : 33px;
                    padding-right  : 33px;

                    &::after {
                      transform: rotate(270deg);
                    }
                  }
                }
              }
            }


          }

          .right {
            position       : absolute;
            bottom         : 0;
            padding        : 12px 20px;
            display        : flex;
            align-items    : center;
            justify-content: center;
            width          : 100%;
            left           : 0;
            right          : 0;
            filter         : drop-shadow(0px -1px 3px rgba(0, 0, 0, 0.08));

            a {
              border        : 1px solid #333;
              border-radius : 100px;
              padding       : 12px 17px !important;
              font-size     : 14px !important;
              display       : block;
              line-height   : 1 !important;
              letter-spacing: -.030rem !important;

              &.tel {
                margin-right    : 12px;
                display         : flex;
                align-items     : center;
                background-color: white;


                img {
                  height      : 16px;
                  width       : 16px;
                  flex        : 0 0 16px;
                  margin-right: 8px;
                }

                .text {
                  display       : block !important;
                  font-size     : 14px;
                  line-height   : 1;
                  letter-spacing: -.030rem;
                }
              }
            }
          }
        }
      }
    }
  }


  #menu-primary-bottom {
    display        : flex;
    align-items    : center;
    justify-content: flex-start;

    >li {

      &.active {

        .sub-menu {
          display: block;
        }
      }

      .sub-menu {
        display       : none;
        position      : fixed;
        z-index       : 9999999;
        width         : 100%;
        height        : 100%;
        left          : 0;
        top           : 108px;
        height        : calc(100vh - 108px);
        overflow      : auto;
        padding-bottom: 40px;
        padding-top   : 0;



        .container {
          grid-template-columns: 1fr;
          padding              : 0 33px;
          gap                  : 24px 0;

          &.is_pro {

            .menu-back,
            .sub-menu-header,
            .wrapper {
              grid-column: 1 / -1;

            }

            .wrapper {
              display              : grid;
              grid-template-columns: 1fr;
              gap                  : 24px 0;
            }
          }

          &::before,
          &::after {
            content: unset;
          }

          .menu-back {
            display      : flex;
            align-items  : center;
            border-bottom: 1px solid #EEEEEE;
            width        : 100vw;
            margin-left  : -50vw;
            position     : relative;
            left         : 50%;
            color        : #A4A2AB;
            font-weight  : bold;
            font-size    : 14px;

            & * {
              pointer-events: none;
            }
          }

          .title,
          .link {
            padding-left : 0 !important;
            padding-right: 0 !important;
          }

        }
      }
    }




  }

}
body.single-vehicule,
body.single-vehicule_adaptable {
  overflow-x: hidden;

  .section-cover-picture {
    height: 135px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 50%;
  }
}

#section-vehicule {
  padding-bottom: 60px;

  .buttons-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .btn:not(:last-child) {
      margin-right: 24px;
    }
  }

  .buttons-group {
    margin-bottom: 30px;
  }

  .btn {
    height: auto;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-top: 30px !important;
    margin-bottom: 0;
    letter-spacing: unset;

    &.btn-secondary {
      padding: 13px 26px !important;
      color: #333333 !important;
      border: 1px solid #A4A2AB;
      @include rfs(1.8rem);
      overflow: hidden;
      display: block !important;
      line-height: 1.2;
      white-space: normal;
      text-align: left;

      span {
        @include rfs(1.2rem);
        text-transform: none;
      }

      &:hover {
        opacity: 0.6;
      }
    }
  }

  .reference {
    margin-top: 8px;
    @include rfs(1.4rem);
    line-height: 16px;
    font-weight: normal;
    letter-spacing: -0.015px;
    color: #858585;
  }

  .description {
    margin-top: 40px;
    margin-bottom: 40px;
    @include rfs(1.8rem);
    line-height: 25px;
    font-weight: lighter;
  }

  .disponibility {
    @include rfs(1.4rem);
    line-height: 16px;
    font-weight: normal;
    letter-spacing: -0.015px;
    color: #C90062;
  }

  .price {
    margin-bottom: 16px;
    @include rfs(4.8rem);
    font-weight: bold;
  }

  .info-list {
    @include rfs(1.8rem);
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      display: flex;
      align-items: center;

      svg {
        margin-right: 8px;
      }

      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }
  }

  .phone-wrapper {
    display: flex;
    align-items: center;

    svg {
      margin-right: 15px;
    }

    .line-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      span.label {
        padding: 0;
        @include rfs(1.3rem);
        color: #A4A2AB;
        text-transform: uppercase;
        font-weight: bold;
      }

      span.phone {
        @include rfs(2.4rem);
        color: #333333;
        font-weight: bold;
      }
    }
  }

  .tooltip-inner {
    padding: 10px;
    background-color: #333;
    color: white;
    @include rfs(1.2rem);
  }

  .tooltip-arrow {
    border-right-color: #333;
  }

  .content-onglet {

    li,
    p {
      font-weight: lighter;
    }
  }

  .files {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .file {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #333333;
      text-decoration: underline;
      text-transform: capitalize;

      &:not(:last-child) {
        margin-bottom: 12px;
      }

      .icon-wrapper {
        width: 46px;
        height: 46px;
        padding: 11px;
        margin-right: 10px;
        @include rfs(1.8rem);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #A4A2AB;
        border-radius: 50%;
      }
    }
  }

  .services-wrapper {
    .item {
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include rfs(2.6rem);
      line-height: 1;
      font-weight: 600;

      svg {
        margin-right: 24px;
      }
    }
  }
}

#more-vehicules {
  padding: 100px 0 120px 0;
  background-color: #F5F5F5;
  position: relative;

  .vehicule-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(389px, 1fr));
    grid-gap: 28px;

    @media screen and (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }

  .inner-wrapper {
    position: relative;
    overflow: hidden;
  }

  &::before,
  &::after {
    content: '';
    height: 100%;
    width: 100%;
    background-color: #F5F5F5;
    position: absolute;
    top: 0;
    bottom: 0;
  }

  &::before {
    left: 0;
    transform: translateX(-100%);
  }

  &::after {
    right: 0;
    transform: translateX(100%);
  }

  .header-wrapper {
    margin-bottom: 60px;
    text-align: center;

    .section-title {
      margin-bottom: 22px;
      font-weight: 300;
    }

    .section-subtitle {
      margin-bottom: 0;
      @include rfs(1.8rem);
      line-height: 25px;
      font-weight: 300;
    }
  }
}


.fancybox-container {
  .hidden-form {
    max-height: 70vh;
    text-align: left !important;

    label {
      margin-bottom: 20px;
      @include rfs(1.6rem);
      display: block;
    }

    select,
    input {
      margin-top: 8px;
      @include rfs(1.6rem);
    }

    input[type="checkbox"] {
      margin-right: 5px;
    }

    .wpcf7-form-control-wrap.rgpd {
      @include rfs(1.2rem);

      a {
        color: #c90062;
      }
    }

    .btn.btn-primary {
      padding: 8px 35px;
    }

    span.wpcf7-form-control-wrap {
      display: block;
    }

    span.wpcf7-list-item {
      margin: 0;
    }

    p {
      @include rfs(1.6rem);
      line-height: 1.5;

      a {
        color: #c90062;
      }
    }

    .wpcf7-acceptance label {
      margin: 0 !important;
    }
  }
}

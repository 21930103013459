#equipe {
  padding-top: 80px;
  h2 {
    margin-bottom: 80px;
    text-align: center;
    .color {
      color: $bleu-handi;
    }
  }
  @media (min-width: $screen-lg) {
    .row:not(:last-child) {
      margin-bottom: 60px;
    }
  }
  .bloc-salarie {
    @media (max-width: $screen-lg) {
      margin-bottom: 50px;
    }
    .bloc-interne {
      margin: 0 auto;
      margin-bottom: 25px;
      max-width: 305px;
      border-bottom: solid 2px $bleu-handi;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }
  .name {
    margin-bottom: 10px;
    @include rfs(2.6rem);
    line-height: 32px;
    font-weight: bold;
  }
  .emploi {
    height: 45px;
    @include rfs(1.6rem);
    line-height: 22px;
    font-weight: 300;
  }
  .bloc-photo {
    max-width: 200px;
    height: auto;
    margin-bottom: 15px;
    .circle {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        border-radius: 50%;
        border: 1px solid #fff;
        top: 5px;
        left: 5px;
      }
      img {
        width: 100%;
        height: auto;
        border-radius: 50%;
      }
    }
  }
}
@font-face {
	font-family: 'Noto Sans';
	src: url('../fonts/NotoSans-Light.woff2') format('woff2'),
	url('../fonts/NotoSans-Light.woff') format('woff'),
	url('../fonts/NotoSans-Light.ttf') format('truetype'),
	url('../fonts/NotoSans-Light.svg#NotoSans-Light') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Noto Sans';
	src: url('../fonts/NotoSans-Medium.woff2') format('woff2'),
	url('../fonts/NotoSans-Medium.woff') format('woff'),
	url('../fonts/NotoSans-Medium.ttf') format('truetype'),
	url('../fonts/NotoSans-Medium.svg#NotoSans-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Noto Sans';
	src: url('../fonts/NotoSans-SemiBold.woff2') format('woff2'),
	url('../fonts/NotoSans-SemiBold.woff') format('woff'),
	url('../fonts/NotoSans-SemiBold.ttf') format('truetype'),
	url('../fonts/NotoSans-SemiBold.svg#NotoSans-SemiBold') format('svg');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Noto Sans';
	src: url('../fonts/NotoSans-Bold.woff2') format('woff2'),
	url('../fonts/NotoSans-Bold.woff') format('woff'),
	url('../fonts/NotoSans-Bold.ttf') format('truetype'),
	url('../fonts/NotoSans-Bold.svg#NotoSans-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Noto Sans';
	src: url('../fonts/NotoSans-Regular.woff2') format('woff2'),
	url('../fonts/NotoSans-Regular.woff') format('woff'),
	url('../fonts/NotoSans-Regular.ttf') format('truetype'),
	url('../fonts/NotoSans-Regular.svg#NotoSans-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}

.page-template-template-options-vehicule-tpmr-product,
.single-particuliers,
.single-gamme_pro,
.single-gamme_public,
.solutions-pour-professionnels,
.solutions-pour-public,
.single-vehicule,
.single-vehicule_adaptable {
  .product-list-slider .slick-current .bloc-photo .circle {
    box-shadow: inset 0 0 0 2px $rose-handi;

    img {
      position: absolute;
      z-index: -1;
    }
  }

  .section-cover-picture {
    height: 135px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 50%;
  }

  .bloc-photo {
    margin-bottom: 15px;

    .circle {
      width: 111px;
      height: 90px;
      position: relative;
      overflow: hidden;
      border-radius: 0;

      @media (max-width: $screen-md) {
        margin: 0 auto;
      }

      /*&:after {
        content: "";
        position: absolute;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        border-radius: 50%;
        border: 1px solid #fff;
        top: 5px;
        left: 5px;
        }*/
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .bloc-photo-large {
    margin-bottom: 20px;

    .circle {
      max-width: 100%;
      position: relative;
      overflow: hidden;
      border-radius: 0;
      background-size: contain;
      background-repeat: no-repeat;
      max-height: 480px;

      @media (max-width: $screen-md) {
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
      }

      /*&:after {
        content: "";
        position: absolute;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        border-radius: 50%;
        border: 1px solid #fff;
        top: 5px;
        left: 5px;
        }*/
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  #section-service,
  #section-vehicule {
    padding-top: 120px;

    .product-main-slider {
      width: 100%;
      max-width: 667px;
    }

    .product-list-slider {
      .slick-track {
        @media screen and (min-width: 1200px) {
          margin-right: 100%;
        }

        .slick-slide {
          margin-right: 28px;
        }
      }
    }

    &.section-service-list {
      padding: 80px 0 120px 0;

      h1 {
        margin: 0;
        /*font-size: 48px;*/
        @include rfs(4.8rem);
        line-height: 58px;
        text-transform: uppercase;
      }

      .row-first {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }

      .logo-title {
        margin-right: 20px;
        width: 80px;
        height: 80px;
      }

      .row-second {
        margin: 25px 0 0;
      }

      .icon.circle {
        padding: 0;
      }

      .bloc-photo {
        margin: auto 0;
        padding: 0;
      }

      .bloc-produits {
        .bloc-produit {
          padding: 20px;
          display: flex;
          align-items: center;
          border: 1px solid transparent;
          transition: all 0.3s ease;
          border-radius: 5px;

          &:hover {
            text-decoration: none;
            background-color: #f5f5f5;
            border: 1px solid #a4a2ab;
          }

          @media all and (max-width: 991px) {
            flex-direction: column;
            align-items: flex-start;
          }
        }

        .row {
          margin-bottom: 50px;
        }
      }

      .txt {
        .title {
          color: $black-handi;
          font-size: 20px;
          @include rfs(2rem);
          line-height: 36px;
          font-weight: 500;
        }

        p {
          color: $black-handi;
          /*font-size: 16px;*/
          @include rfs(1.6rem);
          line-height: 22px;
        }
      }
    }

    .gallery {
      padding: 0 50px 0 0;
    }

    .resume {
      a.btn {
        margin-top: 50px;
        color: $white;
        padding: 10px 35px;
        border-radius: 10px;

        &.rose {
          background-color: $rose-handi;
        }

        img {
          width: auto;
          height: 15px;
          margin: 0px 8px 5px 0;
        }
      }
    }

    .cat-service {
      margin: 0;
      color: $rose-handi;
      /*font-size: 18px;*/
      @include rfs(1.8rem);
      font-weight: normal;
      text-transform: uppercase;
    }

    h1 {
      margin: 0;
      /*font-size: 48px;*/
      @include rfs(58px);
      font-weight: 500;
      text-transform: none;
    }

    .woocommerce-tabs {
      margin-bottom: 90px;

      .onglets-tabs {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 40px;
        border-bottom: solid 2px $gris-eee;
        position: relative;

        @media screen and (max-width: 992px) {
          flex-direction: column;

          .onglet {
            margin-bottom: 20px;
          }
        }

        .onglet {
          margin-right: 70px;
          padding-bottom: 15px;
          cursor: pointer;
          position: relative;
          top: 2px;

          &.bold {
            border-bottom: solid 2px $rose-handi;
          }
        }
      }

      .content-onglet {
        ul {
          @include rfs(1.8rem);
          line-height: 25px;
        }

        p {
          @include rfs(1.8rem);
          line-height: 25px;
        }

        >div {
          display: none;

          &.visible {
            display: block;
          }
        }

        #informations {
          a {
            display: inline-block;
            margin: 5px;
          }

          img {
            display: block;
          }
        }
      }
    }

    .bloc-gallery-resume {
      margin-bottom: 80px;

      .resume {
        @media (max-width: $screen-lg) {
          margin-top: 50px;
        }
      }
    }
  }

  .row-second {
    .gallery {
      display: flex;
      flex-direction: row;
      margin-top: 20px;

      .bloc-photo {
        padding: 0 5px;

        .circle {
          width: 122px;
          height: 122px;

          a {
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 2;
          }
        }
      }
    }
  }

  .buttons-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .btn:not(:last-child) {
      margin-right: 24px;
    }
  }

  .buttons-group {
    margin-bottom: 30px;
  }

  .btn {
    height: auto;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-top: 30px !important;
    margin-bottom: 0;
    letter-spacing: unset;

    &.btn-primary {
      padding: 13px 26px !important;
      @include rfs(1.8rem);
      line-height: 1.2;
    }

    &.btn-secondary {
      padding: 13px 26px !important;
      color: #333333 !important;
      border: 1px solid #A4A2AB;
      @include rfs(1.8rem);
      overflow: hidden;
      display: block !important;
      line-height: 1.2;
      white-space: normal;
      text-align: left;

      span {
        @include rfs(1.2rem);
        text-transform: none;
      }

      &:hover {
        opacity: 0.6;
      }
    }
  }
}

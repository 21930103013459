body.page-template-template-veh-listing,
body.page-template-template-veh-listing-a-vendre,
body.archive {
  overflow-x: hidden;
}

#section-1 {
  padding: 80px 0 100px 0;

  .row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .section-title {
    margin-bottom: 22px;
    font-weight: lighter;
  }

  .section-subtitle {
    margin-bottom: 0;
    @include rfs(1.8rem);
    line-height: 25px;
    font-weight: 300;
  }

  .img-rounded {
    width: 398px;
    max-width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    border-radius: 50%;

    @media screen and (max-width: 992px) {
      margin-bottom: 30px;
    }

    img {
      max-width: 100%;
      height: auto;
    }

    &::after {
      content: "";
      width: calc(100% - 21px);
      height: calc(100% - 21px);
      position: absolute;
      left: 10.5px;
      top: 10.5px;
      border: 2px solid #fff;
      border-radius: 50%;
    }
  }

  .link-arrow {
    margin-top: 40px;
    display: flex;
    font-weight: 700;
    color: #c90062;

    &::after {
      content: "";
      margin-left: 10px;
      width: 50px;
      background-image: url(../images/arrow-pink.svg);
      height: auto;
      display: block;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50%;
      -webkit-transform: translate(0);
      -ms-transform: translate(0);
      transform: translate(0);
      transition: all .5s;
    }

    &:hover {
      text-decoration: none;
      &:after {
        transform: translate(10px);
        transition: all 0.5s;
      }
    }
  }
}

#listing-veh {
  padding: 100px 0 120px 0;
  position: relative;

  &.bg-grey {
    background-color: #F5F5F5;

    &::before, &::after {
      content: '';
      height: 100%;
      width: 100%;
      background-color: #F5F5F5;
      position: absolute;
      top: 0;
      bottom: 0;
    }

    &::before {
      left: 0;
      transform: translateX(-100%);
    }

    &::after {
      right: 0;
      transform: translateX(100%);
    }
  }

  .header-wrapper {
    text-align: center;

    .section-title {
      margin-bottom: 22px;
      font-weight: lighter;
    }

    .section-subtitle {
      margin-bottom: 0;
      @include rfs(1.8rem);
      line-height: 25px;
      font-weight: 300;
    }
  }

  .listing {
    margin-top: 80px;

    .filters {
      label {
        margin-bottom: 8px;
        @include rfs(1.3rem);
        line-height: 25px;
        font-weight: bold;
        text-transform: uppercase;
        color: #A4A2AB;
      }

      select {
        max-width: 300px;
      }
    }

    .listing-wrapper {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
}

.card-item {
  max-width: 389px;
  margin-bottom: 34px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &:hover {
    cursor: pointer;

    .card-content {
      border-left: 1px solid #A4A2AB;
      border-right: 1px solid #A4A2AB;
    }

    .card-image-wrapper {
      .card-more {
        opacity: 1;
      }

      .card-sold {
        opacity: 0;
      }
    }
  }

  .stretched-link::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }

  .card-image-wrapper {
    position: relative;

    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }

    .card-more {
      padding: 10px 20px;
      width: 100%;
      @include rfs(1.6rem);
      line-height: 24px;
      color: #fff;
      background-color: rgba(#C90062, 0.8);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
      text-transform: uppercase;
      opacity: 0;
      transition: opacity 0.3s ease;
      z-index: 1;
    }

    .card-sold {
      padding: 10px 20px;
      width: 100%;
      @include rfs(1.6rem);
      line-height: 24px;
      color: #858585;
      background-color: rgba(#F5F5F5, 0.8);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
      text-transform: uppercase;
      opacity: 1;
      transition: opacity 0.3s ease;
      z-index: 0;
    }
  }

  .card-content {
    padding: 20px;
    border-bottom: 1px solid #A4A2AB;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    transition: 0.3s ease all;

    @media screen and (max-width: 768px) {
      padding: 10px 5px;
    }

    .card-title {
      margin-bottom: 6px;
      @include rfs(2.2rem);
      line-height: 26px;
      font-weight: bold;
    }

    .card-subtitle {
      margin-bottom: 30px;
      color: #858585;
      @include rfs(1.4rem);
      line-height: 16px;
    }
  }

  .card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .card-info-1,
    .card-info-2 {
      @include rfs(1.4rem);
      line-height: 16px;
      display: flex;

      span {
        display: flex;
        align-items: center;

        &:not(:last-child) {
          margin-right: 6px;
        }
      }
    }

    .card-info-3 {
      @include rfs(2.6rem);
      line-height: 32px;
      font-weight: bold;
    }
  }
}


#listing-veh-gamme {
  padding: 80px 0 180px 0;

  .header-wrapper {
    margin-bottom: 60px;
    text-align: center;

    .section-title {
      margin-bottom: 22px;
      font-weight: lighter;
    }

    .section-subtitle {
      margin-bottom: 0;
      @include rfs(1.8rem);
      line-height: 25px;
      font-weight: 300;
    }
  }

  .listing {
    display: grid;
    grid-gap: 0 0;
    grid-template-columns: repeat(auto-fit, minmax(454px, 1fr));
    border-top: 1px solid #A4A2AB;
    border-left: 1px solid #A4A2AB;

    .item {
      padding: 24px 30px 24px 40px;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-right: 1px solid #A4A2AB;
      border-bottom: 1px solid #A4A2AB;

      a {
        width: 100%;
        color: #333333;
        text-align: center;
      }

      .stretched-link::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 99;
      }
    }

    .brand {
      font-weight: bold;
      @include rfs(2.2rem);
      line-height: 26px;
    }
  }
}
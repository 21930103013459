.btn {
  margin-bottom: 10px;
  padding: 14px 30px;
  font-size: 24px;
  line-height: 42px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 20px;
  letter-spacing: 1px;
  transition: 0.3s ease;

  @media (max-width: $screen-md) {
    padding: 15px 12px;
    font-size: 20px;
    line-height: 28px;
  }

  &.btn-primary {
    font-weight: 600;
    letter-spacing: unset;
    border-radius: 100px;
  }

  &.btn-grey {
    color: white;
    background-color: $gray-font;
    font-weight: 600;
    letter-spacing: unset;
    border-radius: 100px;

    &:hover {
      background-color: darken($gray-font, 15%)
    }
  }

  &.btn-blue {
    color: white;
    background-color: $bleu-handi;
    font-weight: 600;
    letter-spacing: unset;
    border-radius: 100px;

    &:hover {
      background-color: darken($bleu-handi, 15%)
    }
  }
}

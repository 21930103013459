#produits,
#espace-pro {
  >div {
    padding-top: 140px;
    outline: 0;

    &:first-child {
      padding-top: 120px;
    }

    .bloc-category {
      .category-description {
        h2 {
          @include rfs(48px, font-size);

          em {
            font-style: normal;
            color: $rose-handi;
          }
        }
      }
    }

    .bloc-produits {
      padding: 0;
    }

    .bloc-produit {
      padding: 20px;
      display: block;
      transition: box-shadow .3s;

      &:hover {
        text-decoration: none;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.16);
        transition: box-shadow .3s;
      }

      .item {
        margin: 0 auto;
        display: flex;
      }

      .icon {
        margin-right: 30px;
        padding: 0;
        width: 80px;
        height: 80px;
      }

      .txt {
        padding-right: 0;

        .title {
          margin-top: 0;
          margin-bottom: 10px;
          /*font-size: 26px;*/
          @include rfs(2.6rem);
          line-height: 36px;
          font-weight: bold;
          color: $black-handi;
        }

        p {
          /*font-size: 16px;*/
          @include rfs(1.6rem);
          line-height: 22px;
          font-weight: lighter;
          color: $black-handi;
        }
      }
    }
  }

  .auto {
    svg {
      .a {
        fill: none;
      }

      .b {
        fill: #c90062;
      }
    }

    .bloc-produit {
      &:hover {
        box-shadow: 0 5px 15px 0 rgba($rose-handi, .2);
        transition: box-shadow .3s;
      }
    }
  }

  .access {
    svg {
      .a {
        fill: #fecb00;
      }
    }

    .bloc-produit {
      &:hover {
        box-shadow: 0 5px 15px 0 rgba($jaune-handi, .2);
      }
    }
  }

  .aide {
    svg {
      .a {
        fill: none;
      }

      .b {
        fill: #2a6ebb;
      }
    }

    .bloc-category .category-description h2 em {
      color: #2a6ebb;
    }

    .bloc-produit {
      &:hover {
        box-shadow: 0 5px 15px 0 rgba($bleu-handi, .2);
      }
    }
  }
}

.particuliers-template,
.page-template,
.page-template-template-custom,
.configuration-template-default {
  .section-cover-picture {
    height: initial !important;
    padding: 200px 0 50px !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    display: flex !important;

    @media all and (max-width: 767px) {
      height: auto;
    }
  }

  .content-cover {
    align-items: flex-end !important;
  }

  .title-cover {
    h1 {
      margin-top: 0 !important;
    }
  }

  .bloc-cover-rose {
    height: 20px !important;
    background-color: #c90062 !important;
    padding: 0 !important;
  }
}

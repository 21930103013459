body {
  &::after {
    content   : '';
    width     : 100%;
    height    : 100%;
    position  : fixed;
    top       : 0;
    background: rgba(0, 0, 0, 0.4);
    transition: 0.3s ease;
    opacity   : 0;
    z-index   : -1;
  }

  &.menu-is-open::after {
    opacity: 1;
    z-index: 1;
  }

  &.home {
    .bloc-cover-rose {
      height: 100px;
    }
  }
}

.menu-back {
  margin : 20px 0;
  display: flex;

  span {
    color: $rose-handi;
  }

  a {
    color          : $rose-handi;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media screen and (min-width: 1280px) {
    display: none;
  }
}

@media (max-width: $screen-md) {
  .navbar .container {
    width    : 100%;
    max-width: 100%;
  }
}

.container {
  width    : 100%;
  max-width: 1640px;
  display  : block;
  margin   : 0 auto;
  padding  : 0;

  @media only screen and (max-width: 1670px) {
    padding-left : 15px;
    padding-right: 15px;
  }
}

div.wpcf7-validation-errors,
div.wpcf7-acceptance-missing {
  border: 0;
}

.fancybox-image,
.fancybox-spaceball {
  width: unset !important;
}

#demande-devis {
  padding            : 100px 0;
  background-repeat  : no-repeat;
  background-position: top right;
  background-color   : #333333;
  margin-bottom      : 70px;

  @media (max-width: $screen-lg) {
    background-position: 50%;
    background-size    : cover;
  }

  h2 {
    margin-bottom: 20px;
    /*font-size  : 48px;*/
    @include rfs(4.8rem);
    line-height: 58px;
  }

  p {
    margin-bottom: 60px;
  }

  h2,
  p,
  a {
    color: $white;
  }

  a.btn {
    padding      : 10px 35px;
    border-radius: 10px;

    &.rose {
      background-color: $rose-handi;
    }
  }
}

.particuliers-list {
  padding-top   : 80px;
  padding-bottom: 180px;

  .col-xs-12 {
    margin-bottom: 24px;
  }

  .section-head {
    max-width    : 812px;
    margin-left  : auto;
    margin-right : auto;
    margin-bottom: 80px;
    text-align   : center;

    .section-title {
      margin-bottom: 22px;
      font-size    : 48px;
      line-height  : 64px;
      font-weight  : lighter;
    }

    .section-subtitle {
      margin     : 0;
      font-size  : 18px;
      line-height: 25px;
      font-weight: lighter;
    }
  }

  .bloc-produit {
    text-decoration: none;

    &:hover,
    &:active {
      text-decoration: none;
    }

    .item {
      padding      : 24px 32px 32px 24px;
      display      : flex;
      border       : 1px solid transparent;
      border-radius: 5px;
      transition   : all 0.3s ease;

      &:hover {
        background-color: #F5F5F5;
        border          : 1px solid #A4A2AB;
      }

      .icon {
        margin-right: 32px;
      }

      h3 {
        margin-top    : 0;
        margin-bottom : 10px;
        font-size     : 26px;
        line-height   : 28px;
        color         : #333333;
        font-weight   : bold;
        letter-spacing: -0.010em;
      }

      p {
        margin-bottom: 0;
        font-size    : 15px;
        line-height  : 21px;
        color        : #333333;
        font-weight  : lighter;
      }
    }
  }
}
#services {
  background-color: $gris-handi;
  padding: 80px;
}

.section-subservice {
  padding: 80px 0;
}

#section-services {
  h2 {
    text-transform: uppercase;
  }

  h2,
  p {
    text-align: center;
  }

  p {
    margin-bottom: 60px;
  }

  .bloc-service {
    &:not(:last-child) {
      .bloc-intern {
        margin-right: 40px;

        @media (max-width: $screen-lg) {
          margin-right: 0;
          margin-bottom: 70px;
        }
      }
    }

    svg {
      position: absolute;
      width: 140px;
      right: -40px;
      top: -40px;

      @media (max-width: 1279px) {
        width: 90px;
        height: 90px;
        right: -10px;
        top: -40px;
      }
    }

    ul {
      line-height: 26px;
      list-style: none;
      padding-left: 0;
    }

    li {
      display: flex;
      align-items: center;
      /*font-size: 22px;*/
      @include rfs(2.2rem);
      line-height: 40px;
      position: relative;

      &:before {
        content: '';
        width: 10px;
        height: 10px;
        position: absolute;
        top: 17px;
        left: 0;
        border-radius: 50%;
      }

      a {
        color: #333;
        margin-left: 20px;
        font-weight: lighter;
      }
    }

    &.auto,
    &.access,
    &.help {
      h3 {
        margin-top: 0;
        margin-bottom: 40px;
        /*font-size: 26px;*/
        @include rfs(2.6rem);
        line-height: 36px;
        font-weight: bold;
      }
    }

    &.auto {
      h3 {
        color: $auto-color;
      }

      li {
        &:before {
          background-color: $rose-handi;
        }
      }
    }

    &.access {
      h3 {
        color: $access-color;
      }

      li {
        &:before {
          background-color: $jaune-handi;
        }
      }
    }

    &.help {
      h3 {
        color: $help-color;
      }

      li {
        &:before {
          background-color: $bleu-handi;
        }
      }
    }

    .bloc-intern {
      position: relative;
      border-radius: 30px;
      padding: 70px 40px 50px;
      background-color: $white;
    }

    h3 {
      /*font-size: 18px;*/
      @include rfs(1.8rem);
    }
  }

  .row-title {
    margin-bottom: 50px;
  }
}

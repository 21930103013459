.section-cover-picture {
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;

  .header-inner-wrapper {
    min-width: 320px;
    padding: 40px 40px 40px 40px;
    position: relative;
    bottom: -51px;
    background-color: white;

    > * {
      color: #333333;
    }
  }

  .img-bottom-cover {
    position: absolute;
  }
  @media (max-width: $screen-md) {
    height: 100%;
    padding: 120px 0 70px;
  }
  &.full-height {
   min-height: 900px;
 }
}

@media (min-width: ) {
  .title-cover {
    padding-top: 120px;
  }
}

.title-cover {
  @media (max-width: $screen-md) {
    max-width: 700px;
  }
  h1 {
    margin-bottom: 30px;
    @include rfs(5.8rem);
    line-height: 1;
    font-weight: 300;
    letter-spacing: -0.015px;

    span {
      font-weight: 800;
    }

    /*@media (max-width: 1280px) {
      font-size: 50px;
      line-height: 58px;
      }*/
    }
    p {
      margin: 0;
      @include rfs(2.2rem);
      line-height: 1.6;
    /*font-size: 24px;
    line-height: 33px;*/
  }
  h1, p {
    color: #FFFFFF;
  }

  .cover-buttons {
    margin-top: 80px;
    display: flex;
    flex-wrap: wrap;

    a {
      margin-bottom: 0;
      @include rfs(2.6rem);
      display: flex;
      align-items: center;

      @media(max-width: 1199px) {
        margin-bottom: 30px;
      }

      .icon {
        display: flex;
        margin-right: 20px;
      }

      &:not(:last-child) {
        margin-right: 60px;

        @media(max-width: 1279px) {
          margin-right: 30px;
        }
      }

      &:nth-child(1) {
        @extend .btn-primary;
      }
      &:nth-child(2) {
        @extend .btn-grey;
      }
      &:nth-child(3) {
        @extend .btn-blue;
      }
    }
  }
}

.content-cover {
  display: flex;
  align-items: center;
}

.cover-slider {
  position: relative;
  .title-cover {
    h1 {
      text-align: center;
      /*font-size: 45px;*/
      @include rfs(4.5rem);
    }
  }
}

.bloc-form-frontpage {
  text-align: center;
  label {
    color: gray;
    font-weight: 400;
    /*font-size: 16px;*/
    @include rfs(1.6rem);
    padding: 10px 20px;
    background-color: white;
    border-right: solid gray 1px;
  }
}

.papillon {
  position: absolute;
  &.small {
    &.rose {
      .a{opacity:0.999;fill:url(#papillon-small-rose);}
      right: 65px;
      top: 175px;
    }
  }
  &.big {
    &.rose {
      .a{fill:url(#papillon-big-rose);}
      top: 550px;
      max-width: 190px;
      right: 165px;
    }
  }
  &.blue {
    .a{fill:url(#papillon-blue);}
    max-width: 150px;
    top: 345px;
    right: 20px;
  }
  &.yellow {
    &.y1 {
      top: 300px;
      right: 120px;
    }
    &.y2 {
      top: 840px;
      right: 15px;
    }
    .a{fill:url(#papillon-yellow);}
  }
}

.bloc-papillon {
 height: 885px;
 position: relative;
 bottom: 0;
 z-index: 999;
 @media(max-width: 1600px) {
  display: none;
}
}
/* Général / thème */
body {
  color: $black-font;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

p {
  /*font-size: 22px;*/
  @include rfs(2.2rem);
  line-height: 36px;
  font-weight: lighter;
  color: $black-handi;
}

h1,
h2 {
  @include rfs(4.8rem);
  line-height: 48px;
  margin-top: 0;
  font-weight: 500;
  margin-bottom: 40px;
}

.pink {
  color: #c90062;
}

.yellow {
  color: #FECB00;
}

.blue {
  color: #2A6EBB;
}

.wrap.container {
  position: relative;
}

/* bloc rose sous les couverture */
.bloc-cover-rose {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: $rose-handi;

  &.fp {
    p {
      margin-bottom: 0;
    }

    .title {
      /*font-size: 30px;*/
      font-weight: 500;
      @include rfs(30px, font-size);
      @include rfs(36px, line-height);
      color: white;
      text-transform: uppercase;
      margin: 0;
    }
  }
}

.gray {
  color: $gray-font;
}


/* Sticky bulle */
.bulle-sticky {
  max-width: 120px;
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 9999;

  @media (max-width: $screen-sm) {
    display: none;
  }
}

.fancybox-content {
  max-width: 800px !important;
  border-radius: 20px;
  text-align: center !important;

  h2 {
    margin-top: 0;
    margin-bottom: 20px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .CTA {
    margin-top: 40px;

    >:not(:last-child) {
      margin-right: 20px;
    }
  }
}

/* Section texte & image */
.txt-picture {
  .wpb_column {
    margin: auto;
  }

  .link-arrow {
    margin-top: 60px;
    display: flex;
    font-weight: bold;
    color: $rose-handi;

    &:after {
      content: "";
      margin-left: 10px;
      width: 50px;
      background-image: url(../images/arrow-pink.svg);
      height: auto;
      display: block;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50%;
      transform: translate(0);
      transition: all 0.5s;
    }

    &:hover {
      text-decoration: none;

      &:after {
        transform: translate(10px);
        transition: all 0.5s;
      }
    }
  }
}

.wpb_single_image {
  margin-bottom: 15px;

  .vc_figure {
    .vc_single_image-wrapper {
      position: relative;

      &:after {
        content: "";
        width: calc(100% - 21px);
        height: calc(100% - 21px);
        position: absolute;
        left: 10.5px;
        top: 10.5px;
        border: 2px solid #fff;
        border-radius: 50%;
      }
    }

    img {
      border-radius: 50%;
    }
  }
}

#section-presentation-1,
#section-presentation-2 {
  padding: 120px 0 80px 0;
}

@media (max-width: $screen-md) {
  #section-presentation-1 {
    padding: 170px 0 250px;
  }
}

#section-presentation-1,
#section-presentation-2 {
  display: flex;

  .wpb_content_element {
    margin: 0;
  }
}

.svg-top {
  position: absolute;
  height: 170px;
  width: auto;
  left: 0;

  &.svg-right {
    right: 0;
    left: auto;
  }

  &.small {
    width: 160px;
    height: 120px;
  }
}

.svg-bottom {
  position: absolute;
  height: 261px;
  width: auto;
  right: 0;
  bottom: 0;

  &.small {
    width: 259px;
    height: 195px;
    z-index: -1;
  }
}
